const getTwitterSearch = {
    "data": [
        {
            "id": "1357342994156851202",
            "text": "RT @ASTRO_NAVER: [PLS RT]\n#CHAEUNWOO ON TRUE BEAUTY\n\n▶ https://t.co/FkagHnmdeI\n▶ https://t.co/nvmIYShfEe\n▶ https://t.co/6U6LoMd7O4\n▶ https:…"
        },
        {
            "id": "1357342990323224584",
            "text": "RT @themikalaleeann: learn how to self sooth. dont react, take time to respond."
        },
        {
            "id": "1357342989727637514",
            "text": "RT @DunkeatDonuts: Good evening! Since we got into a direct message limit, we’ll react fire once we received your order then we’ll react he…"
        },
        {
            "id": "1357342975810887684",
            "text": "RT @danriccio_: It’s great to see more attention being brought to the #FarmersProtest in India. How would you react if your livelihood was…"
        },
        {
            "id": "1357342975408279556",
            "text": "RT @UbahTheBuilder: STEP 1 -&gt; Learn HTML\nSTEP 2 -&gt; Learn CSS\nSTEP 3 -&gt; JavaScript\nSTEP 4 -&gt; Learn GIT\nSTEP 5 -&gt; Learn React or Vue\nSTEP 6 -…"
        },
        {
            "id": "1357342973277601797",
            "text": "@frozenwater333 Even if ur not gay, watching u react like this to the suggestion is always funny https://t.co/Pi4LC7yZVu"
        },
        {
            "id": "1357342961558720514",
            "text": "@NCISNewOrleans YES, We do! He has grown so much thru the seasons. from super lab nerd to a top agent an React team leader"
        },
        {
            "id": "1357342954273140737",
            "text": "RT @pop13031: 本日は、先週みてたReduxですが、ReduxよりHooksってい記事を見たので再度UdemyでReact Hooksの講座を視聴中。\n#今日の積み上げ #reactjs #udemy"
        },
        {
            "id": "1357342952658399232",
            "text": "RT @musaxv: A celebrity clapped back at a person that called her ugly and you're saying it's uneccessary, what right do you have to decide…"
        },
        {
            "id": "1357342941283405826",
            "text": "RT @ShashiTharoor: For GoI to get Indian celebrities to react to Western ones is embarrassing. The damage done to India's global image by G…"
        },
        {
            "id": "1357342940062834689",
            "text": "RT @TwiceBrandRK_2: [\uD83D\uDCDB MINA ARTICLE \uD83D\uDCDB]\n\n- RT AND SPREAD -\nREACT WITH 4TH EMOJI AND RECOMMEND IT \n1⃣ https://t.co/vyuyATfuVF\n2⃣ https://t.co…"
        },
        {
            "id": "1357342932198514694",
            "text": "本日は、先週みてたReduxですが、ReduxよりHooksってい記事を見たので再度UdemyでReact Hooksの講座を視聴中。\n#今日の積み上げ #reactjs #udemy"
        },
        {
            "id": "1357342930722115585",
            "text": "this girl is openly flirting with me but idk how to react\uD83D\uDE2D"
        },
        {
            "id": "1357342915236827153",
            "text": "RT @MelStuart9: @CerchieLucia @ton_1800 @Whatthedatasays @Djsmine2 @bp_goodwin @LapinaMargarita Thank you all for your support. \uD83D\uDC4D How is yo…"
        },
        {
            "id": "1357342914892886016",
            "text": "RT @MayAung2021: ၉:၃၀ ရင် အောက်က Hashtag 2 ခုနဲ့ စည်းလုံးစွာ တူတူ Tweet ကြမယ်နော်\nWW Trend အောင် Tweet မှာ ဖြစ်တဲ့ အတွက် စတစ်ကာ Emoji လုံးဝ…"
        },
        {
            "id": "1357342912363646981",
            "text": "RT @mcbinladen: MEUS AMIGOS DO FUNK CONHECENDO K-POP \n\n KKKKK ( - CUSÃO TÁ DAORA FAZ MAIS REACT LOGO ) \n\n Eles tão querendo mais e tão curt…"
        },
        {
            "id": "1357342910325293058",
            "text": "RT @MayAung2021: ၉:၃၀ ရင် အောက်က Hashtag 2 ခုနဲ့ စည်းလုံးစွာ တူတူ Tweet ကြမယ်နော်\nWW Trend အောင် Tweet မှာ ဖြစ်တဲ့ အတွက် စတစ်ကာ Emoji လုံးဝ…"
        },
        {
            "id": "1357342909847064577",
            "text": "RT @MelStuart9: @CerchieLucia @ton_1800 @Whatthedatasays @Djsmine2 @bp_goodwin @LapinaMargarita Thank you all for your support. \uD83D\uDC4D How is yo…"
        },
        {
            "id": "1357342909255753728",
            "text": "Can you react &amp; change direction while have REALLY good body control? \uD83D\uDC40\n\nEverything we do translates to the game of football \n\nWE MUST COMPLETELY OUTWORK THE NEXT MAN \uD83D\uDE24❤\uD83D\uDCAA‼️\n\n@Black8Tavon\n@KelvinBlack__ \n\n#StudentAthlete\n#HumanMovement\n#FITNESSKING360 https://t.co/cgpBbDCV3w"
        },
        {
            "id": "1357342908647571459",
            "text": "RT @MayAung2021: ၉:၃၀ ရင် အောက်က Hashtag 2 ခုနဲ့ စည်းလုံးစွာ တူတူ Tweet ကြမယ်နော်\nWW Trend အောင် Tweet မှာ ဖြစ်တဲ့ အတွက် စတစ်ကာ Emoji လုံးဝ…"
        }
    ],
    "meta": {
        "newest_id": "1357342994156851202",
        "oldest_id": "1357342908647571459",
        "result_count": 20,
        "next_token": "b26v89c19zqg8o3foskuer33yr1038b2gjvxizh2u8sql"
    }
}

const getTwitterSearchWithFullTweet = {

    "data": [
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "retweeted",
                    "id": "1359862796687732738"
                }
            ],
            "text": "RT @PulseGhana: ‘All these properties in 4 years?’ Ghanaians react to Eugene Arhin’s listed assets in lawsuit https://t.co/dfxBM3hZWp https…",
            "created_at": "2021-02-11T13:57:41.000Z",
            "author_id": "1097170070252466176",
            "entities": {
                "urls": [
                    {
                        "start": 110,
                        "end": 133,
                        "url": "https://t.co/dfxBM3hZWp",
                        "expanded_url": "https://bit.ly/3d91MfD",
                        "display_url": "bit.ly/3d91MfD",
                        "images": [
                            {
                                "url": "https://pbs.twimg.com/news_img/1359862805164396546/vFCnuARK?format=jpg&name=orig",
                                "width": 788,
                                "height": 444
                            },
                            {
                                "url": "https://pbs.twimg.com/news_img/1359862805164396546/vFCnuARK?format=jpg&name=150x150",
                                "width": 150,
                                "height": 150
                            }
                        ],
                        "status": 200,
                        "title": "‘All these properties in 4 years?’ Ghanaians react to Eugene Arhin’s listed assets in lawsuit",
                        "description": "Communications Director at the Presidency, Eugene Arhin, is currently in the news for all the wrong reasons.",
                        "unwound_url": "https://www.pulse.com.gh/news/local/eugene-arhin-ghanaians-react-to-assets-owned-by-communications-director-at-the/8fnnbkg?utm_medium=social&utm_campaign=pulse-ghana&utm_source=Twitter#Echobox=1613051524"
                    }
                ],
                "mentions": [
                    {
                        "start": 3,
                        "end": 14,
                        "username": "PulseGhana"
                    }
                ],
                "annotations": [
                    {
                        "start": 51,
                        "end": 59,
                        "probability": 0.5311,
                        "type": "Person",
                        "normalized_text": "Ghanaians"
                    },
                    {
                        "start": 70,
                        "end": 81,
                        "probability": 0.9872,
                        "type": "Person",
                        "normalized_text": "Eugene Arhin"
                    }
                ]
            },
            "source": "Twitter for Android",
            "id": "1359864187007225857",
            "possibly_sensitive": false
        },
        {
            "lang": "fr",
            "referenced_tweets": [
                {
                    "type": "retweeted",
                    "id": "1359599058223046657"
                }
            ],
            "text": "RT @DrGomi: @gkierzek et la plus grosse étude au monde en population : react étude anglaise, autoprélèvement à domicile https://t.co/9nSX2B…",
            "created_at": "2021-02-11T13:57:41.000Z",
            "author_id": "224255455",
            "entities": {
                "mentions": [
                    {
                        "start": 3,
                        "end": 10,
                        "username": "DrGomi"
                    },
                    {
                        "start": 12,
                        "end": 21,
                        "username": "gkierzek"
                    }
                ]
            },
            "source": "Twitter for iPhone",
            "id": "1359864186138943503",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "text": "Yes, that's exactly how we react when our friends compliment us like that 😜😍 #SonyaHussyn #UshnaShah https://t.co/dDAIhWEVje",
            "created_at": "2021-02-11T13:57:37.000Z",
            "author_id": "2719834218",
            "entities": {
                "urls": [
                    {
                        "start": 101,
                        "end": 124,
                        "url": "https://t.co/dDAIhWEVje",
                        "expanded_url": "https://twitter.com/galaxylollywood/status/1359864169147817985/photo/1",
                        "display_url": "pic.twitter.com/dDAIhWEVje"
                    }
                ],
                "hashtags": [
                    {
                        "start": 77,
                        "end": 89,
                        "tag": "SonyaHussyn"
                    },
                    {
                        "start": 90,
                        "end": 100,
                        "tag": "UshnaShah"
                    }
                ]
            },
            "source": "Twitter for Android",
            "attachments": {
                "media_keys": [
                    "3_1359864165008109574"
                ]
            },
            "id": "1359864169147817985",
            "possibly_sensitive": false
        },
        {
            "lang": "tl",
            "text": "gago ampt napindot ko lang yung HAHA react HAHAHAHHA",
            "created_at": "2021-02-11T13:57:36.000Z",
            "author_id": "1272737609257811969",
            "source": "Twitter for Android",
            "id": "1359864163472867333",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "retweeted",
                    "id": "1359825773310472194"
                }
            ],
            "text": "RT @Imperial_IGHI: Chills, loss of appetite, headaches &amp; muscle aches are all linked with having #COVID19, according to findings from our R…",
            "created_at": "2021-02-11T13:57:35.000Z",
            "author_id": "2609294780",
            "entities": {
                "mentions": [
                    {
                        "start": 3,
                        "end": 17,
                        "username": "Imperial_IGHI"
                    }
                ],
                "hashtags": [
                    {
                        "start": 101,
                        "end": 109,
                        "tag": "COVID19"
                    }
                ]
            },
            "source": "Twitter Web App",
            "id": "1359864158725033988",
            "possibly_sensitive": false
        },
        {
            "lang": "de",
            "text": "React Live ab 18 Uhr - Epic-Showcase, unsterbliche Spiele und ein Katzenanwalt https://t.co/bjKRssax7a",
            "created_at": "2021-02-11T13:57:32.000Z",
            "author_id": "1209964117362192385",
            "entities": {
                "urls": [
                    {
                        "start": 79,
                        "end": 102,
                        "url": "https://t.co/bjKRssax7a",
                        "expanded_url": "http://dlvr.it/RsV5Mf",
                        "display_url": "dlvr.it/RsV5Mf",
                        "images": [
                            {
                                "url": "https://pbs.twimg.com/news_img/1359849695309295617/aw2vehE6?format=jpg&name=orig",
                                "width": 1920,
                                "height": 1080
                            },
                            {
                                "url": "https://pbs.twimg.com/news_img/1359849695309295617/aw2vehE6?format=jpg&name=150x150",
                                "width": 150,
                                "height": 150
                            }
                        ],
                        "status": 200,
                        "title": "Ab 18 Uhr live: Epic-Showcase, unsterbliche Spiele und ein Katzenanwalt",
                        "description": "Diese Woche begleiten wir bei React Live den Epic Showcase, sprechen wir über einen Katzenanwalt und Spiele, die von der Community unterblich gemacht wurden.",
                        "unwound_url": "https://www.gamestar.de/artikel/heute-ab-18-uhr-live-epic-showcase-unsterbliche-spiele-und-ein-katzenanwalt,3366870.html?utm_source=dlvr.it&utm_medium=twitter"
                    }
                ]
            },
            "source": "dlvr.it",
            "id": "1359864148323049474",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "replied_to",
                    "id": "1359863064833703936"
                }
            ],
            "text": "@rjmxrell @Inspiringsafari It's like i don't know who you are and from where you belongs i can suggest you to come to me let's get react like an reactor and let the reaction take place of it's own with it's specific products.",
            "created_at": "2021-02-11T13:57:29.000Z",
            "author_id": "278548540",
            "entities": {
                "mentions": [
                    {
                        "start": 0,
                        "end": 9,
                        "username": "rjmxrell"
                    },
                    {
                        "start": 10,
                        "end": 26,
                        "username": "Inspiringsafari"
                    }
                ]
            },
            "source": "Twitter for Android",
            "id": "1359864133957558283",
            "in_reply_to_user_id": "2584598134",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "text": "Learn to react like.....why am I not surprised 🤔.",
            "created_at": "2021-02-11T13:57:28.000Z",
            "author_id": "1159536994332094464",
            "source": "Twitter for Android",
            "id": "1359864129880809476",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "text": "maybe...... it's because i havent heard anyone call me by dani in such a long time.... that i think my backbone react when i say dani myself. is she.... here???? is she still here??? https://t.co/rvg61vyQ04",
            "created_at": "2021-02-11T13:57:25.000Z",
            "author_id": "79705026",
            "entities": {
                "urls": [
                    {
                        "start": 183,
                        "end": 206,
                        "url": "https://t.co/rvg61vyQ04",
                        "expanded_url": "https://twitter.com/cecedojo/status/1359864118816116738/photo/1",
                        "display_url": "pic.twitter.com/rvg61vyQ04"
                    }
                ],
                "annotations": [
                    {
                        "start": 58,
                        "end": 61,
                        "probability": 0.8442,
                        "type": "Person",
                        "normalized_text": "dani"
                    }
                ]
            },
            "source": "Twitter Web App",
            "attachments": {
                "media_keys": [
                    "16_1359864108988862464"
                ]
            },
            "id": "1359864118816116738",
            "possibly_sensitive": false
        },
        {
            "lang": "tl",
            "text": "Mo pm palang nang mang react ug heart sa story sa messenger/fb basin pa uruy naa tay pa dulngan haha",
            "geo": {
                "place_id": "000df05f060dfd29"
            },
            "created_at": "2021-02-11T13:57:24.000Z",
            "author_id": "864701965212590080",
            "source": "Twitter for iPhone",
            "id": "1359864115217326080",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "retweeted",
                    "id": "1359832589268353031"
                }
            ],
            "text": "RT @Fatima40324778: @kaurnavi11 NO NEED TO DAY IS SIDHARTH'S DAY NO NEGATIVITY. BB14 WANTS US TO REACT SO THEY CAN GET TRP. NO TODAY ONLY S…",
            "created_at": "2021-02-11T13:57:24.000Z",
            "author_id": "1054590551041818627",
            "entities": {
                "mentions": [
                    {
                        "start": 3,
                        "end": 18,
                        "username": "Fatima40324778"
                    },
                    {
                        "start": 20,
                        "end": 31,
                        "username": "kaurnavi11"
                    }
                ],
                "annotations": [
                    {
                        "start": 50,
                        "end": 57,
                        "probability": 0.782,
                        "type": "Person",
                        "normalized_text": "SIDHARTH"
                    }
                ]
            },
            "source": "Twitter for Android",
            "id": "1359864114223321088",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "replied_to",
                    "id": "1359343179649155073"
                }
            ],
            "text": "@NoLifeShaq Hey @NoLifeShaq how about a react to @dearsilas Belafonte or I Got It w/ @BIGKRIT #finishthatmanfinishthatman",
            "geo": {
                "place_id": "8feb9057bc4e1655"
            },
            "created_at": "2021-02-11T13:57:24.000Z",
            "author_id": "154253806",
            "entities": {
                "mentions": [
                    {
                        "start": 0,
                        "end": 11,
                        "username": "NoLifeShaq"
                    },
                    {
                        "start": 16,
                        "end": 27,
                        "username": "NoLifeShaq"
                    },
                    {
                        "start": 49,
                        "end": 59,
                        "username": "dearsilas"
                    },
                    {
                        "start": 85,
                        "end": 93,
                        "username": "BIGKRIT"
                    }
                ],
                "annotations": [
                    {
                        "start": 60,
                        "end": 68,
                        "probability": 0.6999,
                        "type": "Person",
                        "normalized_text": "Belafonte"
                    }
                ],
                "hashtags": [
                    {
                        "start": 94,
                        "end": 121,
                        "tag": "finishthatmanfinishthatman"
                    }
                ]
            },
            "source": "Twitter for iPhone",
            "id": "1359864113858494470",
            "in_reply_to_user_id": "336106103",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "retweeted",
                    "id": "1359862194008190980"
                }
            ],
            "text": "RT @urlDev: R3D53-55;\n\n- Learned about closures and more about functions (HOC),\n\n- Made a simple crud app with PERN stack and passport.js (…",
            "created_at": "2021-02-11T13:57:22.000Z",
            "author_id": "1235170030985392128",
            "entities": {
                "mentions": [
                    {
                        "start": 3,
                        "end": 10,
                        "username": "urlDev"
                    }
                ]
            },
            "source": "Twitter",
            "id": "1359864103645372416",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "retweeted",
                    "id": "1359858450444914692"
                }
            ],
            "text": "RT @Abdul_salam111: @sarbanandsonwal @BJP4Assam React now or never",
            "created_at": "2021-02-11T13:57:21.000Z",
            "author_id": "824150332737486849",
            "entities": {
                "mentions": [
                    {
                        "start": 3,
                        "end": 18,
                        "username": "Abdul_salam111"
                    },
                    {
                        "start": 20,
                        "end": 36,
                        "username": "sarbanandsonwal"
                    },
                    {
                        "start": 37,
                        "end": 47,
                        "username": "BJP4Assam"
                    }
                ]
            },
            "source": "Twitter for Android",
            "id": "1359864099862061056",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "quoted",
                    "id": "1359717166552145920"
                }
            ],
            "text": "The problem is that twitterverse has no reading comprehension, they see single words in a post and react. https://t.co/7BVLtWXuA7",
            "created_at": "2021-02-11T13:57:21.000Z",
            "author_id": "2237071784",
            "entities": {
                "urls": [
                    {
                        "start": 106,
                        "end": 129,
                        "url": "https://t.co/7BVLtWXuA7",
                        "expanded_url": "https://twitter.com/katrinabhaydon/status/1359717166552145920",
                        "display_url": "twitter.com/katrinabhaydon…"
                    }
                ]
            },
            "source": "Twitter for Android",
            "id": "1359864099694338049",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "retweeted",
                    "id": "1359849821998354444"
                }
            ],
            "text": "RT @JR_Sandlin: As you react less to negativity, the more peaceful life becomes.\n\n#MorningJUICE",
            "created_at": "2021-02-11T13:57:20.000Z",
            "author_id": "1188562966033158146",
            "entities": {
                "mentions": [
                    {
                        "start": 3,
                        "end": 14,
                        "username": "JR_Sandlin"
                    }
                ],
                "hashtags": [
                    {
                        "start": 82,
                        "end": 95,
                        "tag": "MorningJUICE"
                    }
                ]
            },
            "source": "Twitter for iPhone",
            "id": "1359864098775785473",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "text": "hii @4guysreaction lepas ni boleh lah react band pulak 🙆🏻‍♀️ my suggestion day6, the rose, nflying",
            "created_at": "2021-02-11T13:57:19.000Z",
            "author_id": "433962078",
            "entities": {
                "mentions": [
                    {
                        "start": 4,
                        "end": 18,
                        "username": "4guysreaction"
                    }
                ]
            },
            "source": "Twitter for iPhone",
            "id": "1359864091309789187",
            "possibly_sensitive": false
        },
        {
            "lang": "ko",
            "text": "▶카톡 ALL542◀ frown 휴대폰소액결제 aunt 상품권 소액결제현금 glance 아이폰소액결제현금 react 구글정보이용료 leave 모바일소액결제 gate 정책미납  소액결제현금 정책미납 99%가능",
            "created_at": "2021-02-11T13:57:17.000Z",
            "author_id": "1317690179394637824",
            "source": "Twitter Web App",
            "id": "1359864085513342978",
            "possibly_sensitive": false
        },
        {
            "lang": "en",
            "referenced_tweets": [
                {
                    "type": "retweeted",
                    "id": "1359791825846849536"
                }
            ],
            "text": "RT @for_HopeWorld: [📰] Article about the 15m long mural by @Baidu_jhopebar at Radiating Youthfulness Village, Gwangju to celebrate j-hope's…",
            "created_at": "2021-02-11T13:57:17.000Z",
            "author_id": "2435044981",
            "entities": {
                "mentions": [
                    {
                        "start": 3,
                        "end": 17,
                        "username": "for_HopeWorld"
                    },
                    {
                        "start": 59,
                        "end": 74,
                        "username": "Baidu_jhopebar"
                    }
                ],
                "annotations": [
                    {
                        "start": 79,
                        "end": 117,
                        "probability": 0.2206,
                        "type": "Place",
                        "normalized_text": "Radiating Youthfulness Village, Gwangju"
                    }
                ]
            },
            "source": "Twitter for iPhone",
            "id": "1359864084968144901",
            "possibly_sensitive": false
        },
        {
            "lang": "fr",
            "text": "Présentation d'une opportunité d'affaires qui pourrait changer votre style de vie \n#YouTube #YouTubersReact #React\nhttps://t.co/JgWX5zFccN",
            "created_at": "2021-02-11T13:57:16.000Z",
            "author_id": "1268329891780575234",
            "entities": {
                "urls": [
                    {
                        "start": 115,
                        "end": 138,
                        "url": "https://t.co/JgWX5zFccN",
                        "expanded_url": "https://youtu.be/GtQc2QcxsPU",
                        "display_url": "youtu.be/GtQc2QcxsPU",
                        "images": [
                            {
                                "url": "https://pbs.twimg.com/news_img/1359864084020224002/glJ4jBZ4?format=jpg&name=orig",
                                "width": 480,
                                "height": 360
                            },
                            {
                                "url": "https://pbs.twimg.com/news_img/1359864084020224002/glJ4jBZ4?format=jpg&name=150x150",
                                "width": 150,
                                "height": 150
                            }
                        ],
                        "status": 200,
                        "title": "Présentation d'une opportunité d'affaires qui pourrait changer votre style de vie",
                        "description": "Vous êtes salarié fonctionnaire ou non, voulez monter un business depuis à la maison cette vidéo est votre secoursContact 221775365767",
                        "unwound_url": "https://www.youtube.com/watch?v=GtQc2QcxsPU&feature=youtu.be"
                    }
                ],
                "hashtags": [
                    {
                        "start": 83,
                        "end": 91,
                        "tag": "YouTube"
                    },
                    {
                        "start": 92,
                        "end": 107,
                        "tag": "YouTubersReact"
                    },
                    {
                        "start": 108,
                        "end": 114,
                        "tag": "React"
                    }
                ]
            },
            "source": "IFTTT",
            "id": "1359864079880507395",
            "possibly_sensitive": false
        }
    ],
    "includes": {
        "users": [
            {
                "description": "My God is able, the source of my strength. Alhamdulillah regardless 🙏",
                "name": "General-Hammad💂🇬🇭",
                "id": "1097170070252466176",
                "protected": false,
                "url": "",
                "verified": false,
                "pinned_tweet_id": "1352687214111678464",
                "created_at": "2019-02-17T16:25:03.000Z",
                "username": "general_Hammad",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1327178755740151808/eFTqauXI_normal.jpg",
                "public_metrics": {
                    "followers_count": 187,
                    "following_count": 470,
                    "tweet_count": 2445,
                    "listed_count": 0
                }
            },
            {
                "description": "Auteur de la BD \"Le Président\" (Ed. @les_arenes) PDG de @MCBGConseil & Prof à @SciencesPo Chroniqueur sur @Europe1 & @CanalPlus #JeMeFeraiVacciner",
                "name": "Ph. Moreau Chevrolet",
                "id": "224255455",
                "protected": false,
                "url": "https://t.co/QI1QAjjZVT",
                "verified": true,
                "pinned_tweet_id": "1356171734819467264",
                "created_at": "2010-12-08T15:03:02.000Z",
                "username": "moreauchevrolet",
                "entities": {
                    "url": {
                        "urls": [
                            {
                                "start": 0,
                                "end": 23,
                                "url": "https://t.co/QI1QAjjZVT",
                                "expanded_url": "https://linktr.ee/pchevrolet",
                                "display_url": "linktr.ee/pchevrolet"
                            }
                        ]
                    },
                    "description": {
                        "hashtags": [
                            {
                                "start": 128,
                                "end": 146,
                                "tag": "JeMeFeraiVacciner"
                            }
                        ],
                        "mentions": [
                            {
                                "start": 36,
                                "end": 47,
                                "username": "les_arenes"
                            },
                            {
                                "start": 56,
                                "end": 68,
                                "username": "MCBGConseil"
                            },
                            {
                                "start": 78,
                                "end": 89,
                                "username": "SciencesPo"
                            },
                            {
                                "start": 106,
                                "end": 114,
                                "username": "Europe1"
                            },
                            {
                                "start": 117,
                                "end": 127,
                                "username": "CanalPlus"
                            }
                        ]
                    }
                },
                "profile_image_url": "https://pbs.twimg.com/profile_images/1314565489708982272/XHeUx7zh_normal.jpg",
                "location": "Paris, France",
                "public_metrics": {
                    "followers_count": 17420,
                    "following_count": 6517,
                    "tweet_count": 129403,
                    "listed_count": 372
                }
            },
            {
                "description": "Films | Celebrities | Lifestyle",
                "name": "Galaxy Lollywood",
                "id": "2719834218",
                "protected": false,
                "url": "https://t.co/DxMVbI8cHF",
                "verified": false,
                "pinned_tweet_id": "1357343863896088591",
                "created_at": "2014-08-09T18:10:32.000Z",
                "username": "galaxylollywood",
                "entities": {
                    "url": {
                        "urls": [
                            {
                                "start": 0,
                                "end": 23,
                                "url": "https://t.co/DxMVbI8cHF",
                                "expanded_url": "http://www.galaxylollywood.com",
                                "display_url": "galaxylollywood.com"
                            }
                        ]
                    }
                },
                "profile_image_url": "https://pbs.twimg.com/profile_images/1068842802379931648/PBc4YsGY_normal.jpg",
                "location": "Pakistan",
                "public_metrics": {
                    "followers_count": 16248,
                    "following_count": 55,
                    "tweet_count": 6985,
                    "listed_count": 18
                }
            },
            {
                "description": "",
                "name": "jish🦋",
                "id": "1272737609257811969",
                "protected": false,
                "url": "",
                "verified": false,
                "pinned_tweet_id": "1294579797411037184",
                "created_at": "2020-06-16T03:48:09.000Z",
                "username": "Nejisher1",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1359847982338777088/knCpMTYb_normal.jpg",
                "public_metrics": {
                    "followers_count": 74,
                    "following_count": 123,
                    "tweet_count": 526,
                    "listed_count": 0
                }
            },
            {
                "description": "The Hamlyn Centre, based within @Imperial_IGHI, develops safe, effective & accessible #imaging, #sensing & #robotics technology to reshape #healthcare worldwide",
                "name": "The Hamlyn Centre",
                "id": "2609294780",
                "protected": false,
                "url": "https://t.co/rP8yGuEfXJ",
                "verified": false,
                "pinned_tweet_id": "1256247847760076805",
                "created_at": "2014-07-07T09:34:05.000Z",
                "username": "ICLHamlynRobots",
                "entities": {
                    "url": {
                        "urls": [
                            {
                                "start": 0,
                                "end": 23,
                                "url": "https://t.co/rP8yGuEfXJ",
                                "expanded_url": "https://www.imperial.ac.uk/hamlyn-centre/",
                                "display_url": "imperial.ac.uk/hamlyn-centre/"
                            }
                        ]
                    },
                    "description": {
                        "hashtags": [
                            {
                                "start": 86,
                                "end": 94,
                                "tag": "imaging"
                            },
                            {
                                "start": 96,
                                "end": 104,
                                "tag": "sensing"
                            },
                            {
                                "start": 107,
                                "end": 116,
                                "tag": "robotics"
                            },
                            {
                                "start": 139,
                                "end": 150,
                                "tag": "healthcare"
                            }
                        ],
                        "mentions": [
                            {
                                "start": 32,
                                "end": 46,
                                "username": "Imperial_IGHI"
                            }
                        ]
                    }
                },
                "profile_image_url": "https://pbs.twimg.com/profile_images/1313483462310060033/lPesArlK_normal.jpg",
                "location": "IGHI, Imperial College London",
                "public_metrics": {
                    "followers_count": 1744,
                    "following_count": 1296,
                    "tweet_count": 4269,
                    "listed_count": 0
                }
            },
            {
                "description": "PC Gamer und Streamer - Spiele aller Art begleiten mich schon seit dem ich 12 Jahre alt bin!! Vom C64 bis PC der Generation 2020! #TwitchDE #PCSpiele #Streamer",
                "name": "@codeSMART78",
                "id": "1209964117362192385",
                "protected": false,
                "url": "https://t.co/i9NaJUqKiA",
                "verified": false,
                "pinned_tweet_id": "1356576718170038279",
                "created_at": "2019-12-25T22:28:35.000Z",
                "username": "codeSMART78",
                "entities": {
                    "url": {
                        "urls": [
                            {
                                "start": 0,
                                "end": 23,
                                "url": "https://t.co/i9NaJUqKiA",
                                "expanded_url": "https://codesmart.optimal-options.de/",
                                "display_url": "codesmart.optimal-options.de"
                            }
                        ]
                    },
                    "description": {
                        "hashtags": [
                            {
                                "start": 130,
                                "end": 139,
                                "tag": "TwitchDE"
                            },
                            {
                                "start": 140,
                                "end": 149,
                                "tag": "PCSpiele"
                            },
                            {
                                "start": 150,
                                "end": 159,
                                "tag": "Streamer"
                            }
                        ]
                    }
                },
                "profile_image_url": "https://pbs.twimg.com/profile_images/1355242395877724161/1TA7obtm_normal.jpg",
                "location": "Köln",
                "public_metrics": {
                    "followers_count": 109,
                    "following_count": 186,
                    "tweet_count": 3096,
                    "listed_count": 1
                }
            },
            {
                "description": "It's like purities can't digest impurities and impurities can't digest purities.",
                "name": "Mr. Naik🔎",
                "id": "278548540",
                "protected": false,
                "url": "",
                "verified": false,
                "created_at": "2011-04-07T14:00:35.000Z",
                "username": "nbharat93",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1357764785199190016/PDjpLOKt_normal.jpg",
                "location": "Goa,India",
                "public_metrics": {
                    "followers_count": 182,
                    "following_count": 2849,
                    "tweet_count": 7602,
                    "listed_count": 0
                }
            },
            {
                "description": "find yourself, your purpose in life and everything will make sense.",
                "name": "Ntalala",
                "id": "1159536994332094464",
                "protected": false,
                "url": "",
                "verified": false,
                "pinned_tweet_id": "1356505030707326977",
                "created_at": "2019-08-08T18:48:57.000Z",
                "username": "Lalantaer",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1350158336751230976/8ZeXIlKO_normal.jpg",
                "location": "Kenya 🇰🇪",
                "public_metrics": {
                    "followers_count": 1727,
                    "following_count": 1724,
                    "tweet_count": 2180,
                    "listed_count": 0
                }
            },
            {
                "description": "hatched 🐣 still a total tsundere🍿",
                "name": "dani",
                "id": "79705026",
                "protected": false,
                "url": "",
                "verified": false,
                "pinned_tweet_id": "1334165274442964992",
                "created_at": "2009-10-04T10:47:45.000Z",
                "username": "cecedojo",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1244798674179215360/a6FbjO2P_normal.jpg",
                "public_metrics": {
                    "followers_count": 210,
                    "following_count": 184,
                    "tweet_count": 8127,
                    "listed_count": 0
                }
            },
            {
                "description": "Human",
                "name": "James",
                "id": "864701965212590080",
                "protected": false,
                "url": "",
                "verified": false,
                "pinned_tweet_id": "1350814328618827778",
                "created_at": "2017-05-17T04:39:48.000Z",
                "username": "JamesRamus",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1355778410669174785/ey_jkDvz_normal.jpg",
                "location": "Cebu City, Central Visayas",
                "public_metrics": {
                    "followers_count": 63,
                    "following_count": 112,
                    "tweet_count": 592,
                    "listed_count": 0
                }
            },
            {
                "description": "",
                "name": "Suraj",
                "id": "1054590551041818627",
                "protected": false,
                "url": "",
                "verified": false,
                "pinned_tweet_id": "1342768175511953408",
                "created_at": "2018-10-23T04:29:15.000Z",
                "username": "SurajWalia27",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1348144102391603200/bMca0BEt_normal.jpg",
                "location": "Haryana, India",
                "public_metrics": {
                    "followers_count": 26,
                    "following_count": 10,
                    "tweet_count": 33430,
                    "listed_count": 0
                }
            },
            {
                "description": "USMC Veteran - MSHROD - Cyclist (N+1) - 2021 @bocogear Gear Ambassador - 2021 @ridecannondale Local Ambassador - 2021 @nuunhydration Nuun Ambassador",
                "name": "Ryan Taylor",
                "id": "154253806",
                "protected": false,
                "url": "",
                "verified": false,
                "created_at": "2010-06-10T19:38:01.000Z",
                "username": "Taylor_1775",
                "entities": {
                    "description": {
                        "mentions": [
                            {
                                "start": 45,
                                "end": 54,
                                "username": "bocogear"
                            },
                            {
                                "start": 78,
                                "end": 93,
                                "username": "ridecannondale"
                            },
                            {
                                "start": 118,
                                "end": 132,
                                "username": "nuunhydration"
                            }
                        ]
                    }
                },
                "profile_image_url": "https://pbs.twimg.com/profile_images/1218195055359528963/SdE4Bcsi_normal.jpg",
                "public_metrics": {
                    "followers_count": 98,
                    "following_count": 257,
                    "tweet_count": 219,
                    "listed_count": 1
                }
            },
            {
                "description": "Follow for latest programming snippets 24x7\nMade by : @manushyaaa , feel free to connect.                   \nNode.js",
                "name": "AltF4 Bot",
                "id": "1235170030985392128",
                "protected": false,
                "url": "",
                "verified": false,
                "pinned_tweet_id": "1321786335548469249",
                "created_at": "2020-03-04T11:48:14.000Z",
                "username": "altf4hashbot",
                "entities": {
                    "description": {
                        "mentions": [
                            {
                                "start": 54,
                                "end": 65,
                                "username": "manushyaaa"
                            }
                        ]
                    }
                },
                "profile_image_url": "https://pbs.twimg.com/profile_images/1324745702665199617/tR-zfOAk_normal.png",
                "location": "CPU",
                "public_metrics": {
                    "followers_count": 541,
                    "following_count": 59,
                    "tweet_count": 126594,
                    "listed_count": 19
                }
            },
            {
                "description": "mechanical engineer👨‍🎓👨‍🎓",
                "name": "hirak Jyoti das",
                "id": "824150332737486849",
                "protected": false,
                "url": "",
                "verified": false,
                "created_at": "2017-01-25T07:02:06.000Z",
                "username": "senapati_hirak",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1219909527190945792/CwM4As6w_normal.jpg",
                "location": "guwahati",
                "public_metrics": {
                    "followers_count": 177,
                    "following_count": 315,
                    "tweet_count": 9374,
                    "listed_count": 0
                }
            },
            {
                "description": "I laugh..I love..I believe..pro-life..support the military..Back the Blue",
                "name": "Shannon K",
                "id": "2237071784",
                "protected": false,
                "url": "",
                "verified": false,
                "pinned_tweet_id": "1119978135502630914",
                "created_at": "2013-12-09T05:32:56.000Z",
                "username": "shannyk36",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1327815917951217668/A3hdDz-6_normal.jpg",
                "location": "South Carolina, USA",
                "public_metrics": {
                    "followers_count": 1246,
                    "following_count": 1826,
                    "tweet_count": 60821,
                    "listed_count": 23
                }
            },
            {
                "description": "{215✈️515} |5’9 180| true freshman wide receiver @ Ellsworth C.C.|banksgabriel29@gmail.com| 515-428-6458|  #speedkills Isaiah 41:10",
                "name": "gabe ''Philly'' banks",
                "id": "1188562966033158146",
                "protected": false,
                "url": "https://t.co/lGwhEo1Pbj",
                "verified": false,
                "created_at": "2019-10-27T21:08:05.000Z",
                "username": "gabe_banks4",
                "entities": {
                    "url": {
                        "urls": [
                            {
                                "start": 0,
                                "end": 23,
                                "url": "https://t.co/lGwhEo1Pbj",
                                "expanded_url": "http://sportsthread.com/profile/324090",
                                "display_url": "sportsthread.com/profile/324090"
                            }
                        ]
                    },
                    "description": {
                        "hashtags": [
                            {
                                "start": 107,
                                "end": 118,
                                "tag": "speedkills"
                            }
                        ]
                    }
                },
                "profile_image_url": "https://pbs.twimg.com/profile_images/1341634382638051329/2105mT8H_normal.jpg",
                "location": "Southwest, PA",
                "public_metrics": {
                    "followers_count": 174,
                    "following_count": 921,
                    "tweet_count": 784,
                    "listed_count": 0
                }
            },
            {
                "description": "i tweet bout my life. dont follow",
                "name": "han",
                "id": "433962078",
                "protected": false,
                "url": "https://t.co/kuL47RZHd9",
                "verified": false,
                "created_at": "2011-12-11T07:23:26.000Z",
                "username": "hanysuhas",
                "entities": {
                    "url": {
                        "urls": [
                            {
                                "start": 0,
                                "end": 23,
                                "url": "https://t.co/kuL47RZHd9",
                                "expanded_url": "http://www.instagram.com/hanysuhas",
                                "display_url": "instagram.com/hanysuhas"
                            }
                        ]
                    }
                },
                "profile_image_url": "https://pbs.twimg.com/profile_images/1349701474930966529/W4Srgl8n_normal.jpg",
                "location": "venice manjung",
                "public_metrics": {
                    "followers_count": 744,
                    "following_count": 523,
                    "tweet_count": 16105,
                    "listed_count": 0
                }
            },
            {
                "description": "",
                "name": "네이버◎ 정식업체 24시 【☎O1O-8498-5129】 올티켓 카톡 ALL542",
                "id": "1317690179394637824",
                "protected": false,
                "url": "",
                "verified": false,
                "created_at": "2020-10-18T04:53:22.000Z",
                "username": "SkyeAnd04764274",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1338946769338560512/ELVKxj9N_normal.jpg",
                "public_metrics": {
                    "followers_count": 168,
                    "following_count": 1,
                    "tweet_count": 13,
                    "listed_count": 0
                }
            },
            {
                "description": "#hoseok: tu sigues mis pasos pero no llena mi huella",
                "name": "nay¹¹¹🤍hobiuary",
                "id": "2435044981",
                "protected": false,
                "url": "",
                "verified": false,
                "pinned_tweet_id": "499345336016453633",
                "created_at": "2014-04-09T06:53:28.000Z",
                "username": "xonaylea",
                "entities": {
                    "description": {
                        "hashtags": [
                            {
                                "start": 0,
                                "end": 7,
                                "tag": "hoseok"
                            }
                        ]
                    }
                },
                "profile_image_url": "https://pbs.twimg.com/profile_images/1357161181866299395/xHOvleVr_normal.jpg",
                "location": "21 , she/her , chicago ",
                "public_metrics": {
                    "followers_count": 452,
                    "following_count": 404,
                    "tweet_count": 87862,
                    "listed_count": 2
                }
            },
            {
                "description": "Je suis gentil",
                "name": "Moïse ba",
                "id": "1268329891780575234",
                "protected": false,
                "url": "",
                "verified": false,
                "created_at": "2020-06-03T23:53:22.000Z",
                "username": "BaMamadouMouss3",
                "profile_image_url": "https://pbs.twimg.com/profile_images/1268594515465711621/PvgHygqD_normal.jpg",
                "public_metrics": {
                    "followers_count": 47,
                    "following_count": 226,
                    "tweet_count": 51,
                    "listed_count": 0
                }
            }
        ]
    },
    "meta": {
        "newest_id": "1359864187007225857",
        "oldest_id": "1359864079880507395",
        "result_count": 20,
        "next_token": "b26v89c19zqg8o3foskv9cz87cwtd5yo83dlldqho4pdp"
    }
}

/*const getTwitterSearchWithFullTweet = {

    "data": []
}*/

    const MOCK_API = {
    getTwitterSearch,
    getTwitterSearchWithFullTweet
}

export default MOCK_API;